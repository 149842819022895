<template>

    <div>

        <!-- Table Container Card -->
        <b-card
            no-body
            class="mb-0">

            <div class="m-2">

                <!-- Table Top -->
                <b-row>

                    <!-- Per Page -->
                    <b-col
                        cols="12"
                        md="6"
                        class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Mostrar</label>
                        <v-select
                            v-model="perPage"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                        />
                        <label>registros</label>
                    </b-col>

                    <!-- Search -->
                    <b-col
                        cols="12"
                        md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input
                                v-model="searchQuery"
                                class="d-inline-block mr-1"
                                placeholder="Buscar ..."
                            />
                            <b-button
                                variant="primary"
                                :to="{ name: 'notices-create' }">
                                <span class="text-nowrap">Agregar Noticia</span>
                            </b-button>
                        </div>
                    </b-col>
                </b-row>

            </div>

            <b-table
                ref="refNoticeListTable"
                class="position-relative"
                :items="fetchNotices"
                responsive
                :fields="tableColumns"
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty
                empty-text="No se encontraron registros coincidentes"
                :sort-desc.sync="isSortDirDesc"
            >

                <template #cell(notice_type_name)="data">
                    <b-badge
                        pill
                        :variant="data.item.notice_type.color"
                    >
						{{ data.item.notice_type.name }}
                    </b-badge>
                </template>

                <template #cell(resource)="data">
                    <b-img
                        v-if="data.item.notice_type_id === 1"
                        width="100"
                        :src="data.item.resource"
                        :text="null"
                        variant="light-success"
                    />
                    <b-link @click="openLinkAnotherTab(`https://www.youtube.com/watch?v=${data.item.resource}`)" v-else>{{ `https://www.youtube.com/watch?v=${data.item.resource}` }}</b-link>
                </template>

                <template #cell(active)="data">
                    <b-badge
                        v-if="data.item.active"
                        pill
                        variant="light-success"
                    >
                        ACTIVADO
                    </b-badge>
                    <b-badge
                        v-else
                        pill
                        variant="light-danger"
                    >
                        DESACTIVADO
                    </b-badge>
                </template>

                <template #cell(actions)="data">
                    <b-dropdown
                        variant="link"
                        no-caret
                        :right="$store.state.appConfig.isRTL">

                        <template #button-content>
                            <feather-icon
                                icon="MoreVerticalIcon"
                                size="16"
                                class="align-middle text-body"
                            />
                        </template>

                        <b-dropdown-item :to="{ name: 'notices-edit', params: { id: data.item.id } }">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Editar</span>
                        </b-dropdown-item>

                        <b-dropdown-item
                            v-if="data.item.active"
                            @click="desactivateNotice(data.item.id)"
                        >
                            <feather-icon icon="EyeOffIcon" />
                            <span class="align-middle ml-50">Desactivar</span>
                        </b-dropdown-item>

                        <b-dropdown-item
                            v-if="!data.item.active"
                            @click="activateNotice(data.item.id)"
                        >
                            <feather-icon icon="EyeIcon" />
                            <span class="align-middle ml-50">Activar</span>
                        </b-dropdown-item>

                        <b-dropdown-item @click="deleteNotice(data.item.id)">
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">Eliminar</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </template>

            </b-table>
            <div class="mx-2 mb-2">
                <b-row>

                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Mostrando {{ dataMeta.from }} al {{ dataMeta.to }} de {{ dataMeta.of }} registros</span>
                    </b-col>

                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end">

                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalNotices"
                            :per-page="perPage"
                            first-number
                            last-number
                            class="mb-0 mt-1 mt-sm-0"
                            prev-class="prev-item"
                            next-class="next-item">

                            <template #prev-text>
                                <feather-icon
                                    icon="ChevronLeftIcon"
                                    size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                    icon="ChevronRightIcon"
                                    size="18"
                                />
                            </template>
                        </b-pagination>

                    </b-col>

                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>

    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import { useToast } from 'vue-toastification/composition';
    import noticeStoreModule from '../noticeStoreModule';
    import { onUnmounted } from '@vue/composition-api';
    import { avatarText } from '@core/utils/filter';
    import useNoticesList from './useNoticesList';
    import VueSweetalert2 from 'vue-sweetalert2';
    import vSelect from 'vue-select';
    import store from '@/store';
    import Vue from 'vue';
    import 'animate.css';

    Vue.use(VueSweetalert2);

    export default {
        components: {
            vSelect
        },
        data () {
            return {
                typesOptions: []
            }
        },
        setup() {

            // Use toast
            const toast = useToast();

            const NOTICE_APP_STORE_MODULE_NAME = 'app-notice';

            // Register module
            if (!store.hasModule(NOTICE_APP_STORE_MODULE_NAME)) store.registerModule(NOTICE_APP_STORE_MODULE_NAME, noticeStoreModule);

            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule(NOTICE_APP_STORE_MODULE_NAME)) store.unregisterModule(NOTICE_APP_STORE_MODULE_NAME);
            });

            const {
                fetchNotices,
                tableColumns,
                perPage,
                currentPage,
                totalNotices,
                dataMeta,
                perPageOptions,
                searchQuery,
                sortBy,
                isSortDirDesc,
                refNoticeListTable,
                refetchData
            } = useNoticesList();

            const desactivateNotice = (id) => {
		
                Vue.swal({
                    title: '¿Está seguro(a) de desactivar la noticia?',
                    text: "¡Si no lo está, puede cancelar la acción!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Sí, desactivar!',
                    cancelButtonText: 'Cancelar',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1'
                    },
                    showClass: {
                        popup: 'animate__animated animate__tada'
                    },
                    buttonsStyling: false
                }).then(result => {

                    if (result.value) 
                    {
                        store.dispatch('app-notice/desactivateNotice', { id })
                            .then( response => {

                                refetchData();

                                toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: response.data.message,
                                        icon: 'CheckIcon',
                                        variant: 'success'
                                    }
                                });

                            })
                            .catch( () => {

                                toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'Error al desactivar la noticia',
                                        icon: 'AlertTriangleIcon',
                                        variant: 'danger'
                                    }
                                });

                            });
                    }

                });

            };

            const activateNotice = (id) => {

                Vue.swal({
                    title: '¿Está seguro(a) de activar la noticia?',
                    text: "¡Si no lo está, puede cancelar la acción!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Sí, activar!',
                    cancelButtonText: 'Cancelar',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1'
                    },
                    showClass: {
                        popup: 'animate__animated animate__tada'
                    },
                    buttonsStyling: false
                }).then(result => {

                    if (result.value) 
                    {
                        store.dispatch('app-notice/activateNotice', { id })
                            .then( response => {

                                refetchData();

                                toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: response.data.message,
                                        icon: 'CheckIcon',
                                        variant: 'success'
                                    }
                                });

                            })
                            .catch( () => {

                                toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'Error al activar la noticia',
                                        icon: 'AlertTriangleIcon',
                                        variant: 'danger'
                                    }
                                });

                            });
                    }

                });

            };

            const openLinkAnotherTab = (url) => {
                window.open(url, '_blank')
            };

            const deleteNotice = (id) => {
                Vue.swal({
                    title: '¿Estás seguro de eliminar la noticia?',
                    text: "¡Si no lo está, puede cancelar la acción!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Sí, eliminar!',
                    cancelButtonText: 'Cancelar',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1'
                    },
                    showClass: {
                        popup: 'animate__animated animate__tada'
                    },
                    buttonsStyling: false
                }).then(result => {
                    if (result.value) {
                        store.dispatch('app-notice/deleteNotice', { id })
                            .then( (response) => {
                                refetchData();
                                toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: response.data.message,
                                        icon: 'CheckIcon',
                                        variant: 'success'
                                    }
                                });
                            })
                            .catch( () => {
                                toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'Error al eliminar la noticia',
                                        icon: 'AlertTriangleIcon',
                                        variant: 'danger'
                                    }
                                });
                            });
                    }
                });
            };

            return {
                // Sidebar
                fetchNotices,
                tableColumns,
                perPage,
                currentPage,
                totalNotices,
                dataMeta,
                perPageOptions,
                searchQuery,
                sortBy,
                isSortDirDesc,
                refNoticeListTable,
                refetchData,

                // Filter
                avatarText,

                // METHODS
                openLinkAnotherTab,
                desactivateNotice,
                activateNotice,
                deleteNotice
            }
        }
    }

</script>

<style lang="scss" scoped>

    .media {
        align-items: center;
    }

    .per-page-selector {
        width: 90px;
    }

</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
    @import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>