import { ref, watch, computed } from '@vue/composition-api';
import store from '@/store';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default function useNoticesList() {
    // Use toast
    const toast = useToast();

    const refNoticeListTable = ref(null);

    // Table Handlers
    let tableColumns = [
        { key: 'notice_type_name', sortable: true, label: 'Tipo de noticia' },
        { key: 'resource', sortable: false, label: 'Recurso' },
        { key: 'active', sortable: true, label: 'Estado' },
        { key: 'actions', label: 'Opciones' }
    ];

    const perPage = ref(10);
    const totalNotices = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref('');
    const sortBy = ref('id');
    const isSortDirDesc = ref(false);

    const dataMeta = computed(() => {
        const localItemsCount = refNoticeListTable.value ? refNoticeListTable.value.localItems.length : 0;
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalNotices.value,
        }
    });

    const refetchData = () => {
        refNoticeListTable.value.refresh();
    };

    watch([currentPage, perPage, searchQuery], () => {
        refetchData();
    });

    const fetchNotices = (ctx, callback) => {
        store
            .dispatch('app-notice/fetchNotices', {
                q: searchQuery.value,
                perPage: perPage.value,
                page: currentPage.value,
                sortBy: sortBy.value,
                sortDesc: isSortDirDesc.value
            })
            .then(response => {
                const { notices, total } = response.data;
                callback(notices);
                totalNotices.value = total;
            })
            .catch(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error al obtener la lista de noticias',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger'
                    }
                });
            })
    };

    return {
        fetchNotices,
        tableColumns,
        perPage,
        currentPage,
        totalNotices,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refNoticeListTable,

        refetchData
    }
}